import React, {Component} from 'react';
import styled from 'styled-components';
import {media} from "utils/Media"

const FooterStyles = styled.footer`
  width: 100%;
  font: 12.5px/1.2 'Noto Sans', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  .df {
    margin: 0 auto;
    padding: 0;
    font-size: inherit;
    -webkit-font-smoothing: antialiased;
    line-height: inherit;
    max-width: 1300px;

    @media ${media.md} {
      padding: 1rem 2rem;
    }
    &__links {
      padding: 1rem 0 0.25rem 0;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0;
      list-style: none;
      li {
        padding: 0 3.5px 0 0;
        margin: 0;
        line-height: 1.8;
        @media ${media.md} {
          line-height: 2.5;  
        }
        a {
          color: #333333;
          text-decoration: none;
          will-change: color;
          transition: color 0.25s ease-in-out;
          &:hover,
          &:active {
            color: #007bff;
          }
        }
      }
      @media only screen and (min-width: 1024px) {
        justify-content: flex-start;
      }
    }
    p {
        margin-top: 1rem;
        font-size: 0.75rem;
        text-align: center;
        color: #333333;
      @media only screen and (min-width: 1024px) {
        text-align: left;
        text-align: left;
      }
    }
    @media ${media.sm} {

    }
  }
`;

class Footer extends Component {
    openDialog = (e) => {
        if (typeof window !== 'undefined' && typeof window.__dataLayer !== 'undefined') {
            window.__dataLayer.OneTrustDialog(e)
        }
    }

    render() {
        return (
            <FooterStyles>
                <div className="df">
                    <ul className="df__links">
                        <li>
                            <a className="no-interstitial-check" href="https://support.disney.com/hc/en-gb">
                                Help •
                            </a>
                        </li>
                        <li>
                            <a className="no-interstitial-check" href="https://disney.co.uk/sign-up">
                                Register •
                            </a>
                        </li>
                        <li>
                            <a
                                target=""
                                className="no-interstitial-check"
                                href="https://disneytermsofuse.com/english-united-kingdom/"
                            >
                                Terms of Use •
                            </a>
                        </li>
                        <li>
                            <a
                                target=""
                                className="no-interstitial-check"
                                href="https://privacy.thewaltdisneycompany.com/en/current-privacy-policy/privacy-notice/"
                            >
                                UK &amp; EU Privacy Rights •
                            </a>
                        </li>
                        <li>
                            <a
                                target=""
                                className="no-interstitial-check"
                                href="https://privacy.thewaltdisneycompany.com/en/current-privacy-policy/"
                            >
                                Privacy Policy •
                            </a>
                        </li>
                        <li>
                            <a
                                target=""
                                className="no-interstitial-check"
                                href="https://privacy.thewaltdisneycompany.com/en/current-privacy-policy/cookies-policy/"
                            >
                                Cookie Policy •
                            </a>
                        </li>
                        <li>
                            <a className="no-interstitial-check" onClick={(e) => this.openDialog(e)} href="#" role="button">
                                Manage Your Cookie Settings •
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://preferences-mgr.truste.com/?pid=disney01&amp;aid=disneyemea01&amp;type=Disneyemea"
                            >
                                Interest-based Ads •
                            </a>
                        </li>
                        <li>
                            <a
                                className="no-interstitial-check"
                                href="https://disney.co.uk/taxstrategy">
                                Tax Strategy Statement •
                            </a>
                        </li>
                        <li>
                            <a
                                className="no-interstitial-check"
                                href="https://disney.co.uk/internet-safety">
                                Internet Safety •
                            </a>
                        </li>
                        <li>
                            <a
                                className="no-interstitial-check"
                                href="https://disney.co.uk/about-us">
                                About Us •
                            </a>
                        </li>
                        <li>
                            <a
                                className="no-interstitial-check"
                                href="https://thewaltdisneycompany.eu">
                                The Walt Disney Company
                            </a>
                        </li>
                    </ul>

                    <p>
                        &copy; Disney &copy; Disney•Pixar &copy; &amp; &trade; Lucasfilm LTD
                        &copy; Marvel. All Rights Reserved
                    </p>
                </div>
            </FooterStyles>
        )
    }
}

export default Footer;