import React, {Component} from "react"
import PropTypes from "prop-types"
import styled, {ThemeProvider} from "styled-components"
import 'scss/main.scss'
import SEO from "components/Seo/Seo"
import GlobalStyles from "utils/GlobalStyles";
import {media} from "utils/Media"
import theme from "utils/Theme"
import Header from "../Header/Header"
import Footer from "components/Footer/Footer"
import {handleLinkClick} from "js/handleLinkClick"
import icon from "../../images/white-diamond.svg";
import { NewHeader } from "../NewHeader"
import Countdown from "../Header/Countdown"
// import Snowfall from 'react-snowfall'


const scrollToElement = require('scroll-to-element');

const BestAvailability = styled.div`
  font-family: ${props => props.theme.font.family.bold};
  font-weight: bold;
  color: ${props => props.theme.colors.navy};
  font-size: 1rem;
  text-transform: uppercase;
  text-align: center;
  padding: 0.5rem 1rem;
  background-color: ${props => props.theme.colors.blue};

  @media ${media.sm} {
    font-size: 20px;
  }

  img {
    display: none;

    @media ${media.sm} {
      display: inline-block;
      padding-bottom: 7px;
    }
  }
`

class Layout extends Component {
  componentDidMount() {
    this.interstitialCheck()
    this.dcfTracking()
    const hash = window.location.hash.split("?");
    
    if (hash[0] && hash[0].includes('#')) {
      setTimeout(() => {
        scrollToElement(hash[0]);
      }, 200)
    } else {
      scrollToElement('body', {
        duration: 200
      });

     
      
    }
    
    window.addEventListener('keydown', this.handleFirstTab);

    const header = document.getElementById("header");
    const siteWrap = document.getElementById("site-wrap");
    if (header) {
      let headerHeight = header.clientHeight;
      siteWrap.style.paddingTop = `${headerHeight +  1}px`;
      window.addEventListener("resize", () => {
        headerHeight = header.clientHeight;
        siteWrap.style.paddingTop = `${headerHeight + 1}px`;
      });
    }
  }
  
  interstitialCheck() {
    
    // Do not fire following code if already on interstitial
    if (window.location.pathname !== '/interstitial/') {
      
      // Grab all links
      const links = document.getElementById('site-wrap').getElementsByTagName('a');
      
      // Loop links
      Array.prototype.forEach.call(links, link => {
        
        // Skip links that have a class with no-interstitial-check
        if (!link.classList.contains('no-interstitial-check') && link.hasAttribute('href')) {
          
          // Add event listener to each
          link.addEventListener("click", function (event) {
            handleLinkClick(event, link);
          });
          
        }
      })
    }
  }
  
  handleFirstTab(e) {
    if (e.keyCode === 9) { // the "I am a keyboard user" key
      document.body.classList.add('user-is-tabbing');
      window.removeEventListener('keydown', this.handleFirstTab);
    }
  }
  
  dcfTracking() {
    if (typeof window !== 'undefined' && typeof window.__dataLayer !== 'undefined') {
      window.__dataLayer.page.page_name = `emea:uk:disney:frozenthemusical:${this.props.pageName}`;
      window.__dataLayer.trackPage()
      console.log('Tracking is set')
    }
  }
  
  render() {
    return (
      <ThemeProvider theme={theme}>

        <div id="site-wrap">
          {!this.props.interstitial &&
            // <Header
            //   categorySlug={this.props.categorySlug}
            //   categoryTitle={this.props.category}
            //   currentPage={this.props.currentPage}
            //   enrichmentHeader={this.props.enrichmentHeader}
            //   subMenuHidden={this.props.subMenuHidden}
            //   pageName={this.props.pageName}
            // />
            <NewHeader />
            }
          <SEO
            slug={this.props.slug}
            title={this.props.title}
            description={this.props.description}
          />
          <GlobalStyles/>
          
          <main id="main"
                className={`${this.props.enrichmentHeader && 'enrichmentHeader'} ${this.props.interstitial && 'interstitial'} ${this.props.subMenuHidden && 'subMenuHidden'}`}>
            {!this.props.interstitial && this.props.pageName !== "access" &&
            <>
                <BestAvailability>
                <img src={icon} alt=""/>&nbsp;&nbsp;
                {/* BEST AVAILABILITY MARCH – APRIL ON THURS 7PM, SAT & SUN 5:30PM. SOME GOOD SEATS SUN 1PM */}
                <span className="uppercase">Final seats remaining!</span>
                {/* <span className="uppercase">May Half-Term: Good seats still available for some Eve Performances</span> */}
                {/* <span className="uppercase">Great seats available for May and June performances</span> */}
                {/* <span className="uppercase">Great seats available for April and May performances</span> */}
                {/* <span className="uppercase">SOME GOOD SEATS STILL AVAILABLE FOR MIDWEEKS OVER THE FESTIVE PERIOD</span> */}
                &nbsp;&nbsp; <img src={icon} alt=""/>
              </BestAvailability>
              <Countdown />
            </>
            }
            {this.props.children}
          </main>
          {!this.props.interstitial && <Footer/>}
        </div>
      </ThemeProvider>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout