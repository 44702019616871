import React, { useEffect, useRef, useState } from "react";
import { Link } from "gatsby";

import DropDown from "./DropDown";
import DesktopDropDown from "./DesktopDropDown";

const Navigation = ({ setOpen, open, videoOpen, setVideoOpen }) => {
  const handleClick = () => {
    if (setOpen) {
      setOpen(false);
    }
  };

  // const handleTrailerOpen = () => {
  //   if (videoOpen === true || videoOpen === false) {
  //     setVideoOpen(!videoOpen);
  //   }
  // };

  const ticketInfo = [
    {
      url: `/ticket-information`,
      text: `Ticket Information`,
    },
    {
      url: `/groups`,
      text: `Groups & Education`,
    },
  ];
  const show = [
    {
      url: `/gallery`,
      text: `Gallery`,
    },
    {
      url: `/cast-and-creatives`,
      text: `Cast & Creative`,
    },
  ];
  const discoverMore = [
    {
      url: `https://www.frozeneducation.co.uk/`,
      external: true,
      text: `Beyond Frozen: Education Resources`,
    },
    {
      url: `/bringing-frozen-to-life/`,
      text: `Bringing FROZEN to Life - Norwegian Style`,
    },
    {
      url: `/let-it-go/`,
      text: `What makes ‘Let it go’ so catchy?`,
    },
    {
      url: `/hygge-t4/`,
      text: `Hygge T4`,
    },
  ];
  return (
    <ul className="nav">
      <DesktopDropDown text="TICKET INFO" subLinks={ticketInfo} width="190" />
      <DropDown text="TICKET INFO" subLinks={ticketInfo} />
      <li onClick={() => handleClick()}>
        <Link to="/access" className="navigation-link">
          <span className="sr-only">ACCESS</span>
          ACCESS
        </Link>
      </li>
      <DesktopDropDown text="ABOUT THE SHOW" subLinks={show} width="160" />
      <DropDown text="SHOW" subLinks={show} />
      <li onClick={() => handleClick()}>
        <Link to="/faqs"  className="navigation-link">
          <span className="sr-only">FAQ</span>
          FAQ
        </Link>
      </li>
      <DesktopDropDown text="DISCOVER MORE" subLinks={discoverMore} width="360" />
      <DropDown text="DISCOVER MORE" subLinks={discoverMore} />
    </ul>
  );
};
export default Navigation;

